<template>
    <div id="container">
        <div class="header">
                <van-nav-bar
                title=""
                left-text="返回"
                right-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                />
            </div>
            <div class="logo">
                <!-- <img src="../../assets/images/title.png" alt=""> -->
                    <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
            </div>
            <!--  -->
            <div class="content">
                   <table cellspacing="0" style="width:100%" class="table">
                        <tr style="">
                            <th  style="    width: 50px;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    font-weight: 700;" class="th" v-for="(item, index) in option.column" :key="index">{{ item.label }}</th>
                        </tr>
                        <tr v-for="(item, index) in tableData" :key="index" class="list-tr">
                            <td v-for="(context, i) in option.column" :key="i">{{ item[context.prop] }}</td>

                            <!-- <td v-for="(context, i) in option.column" :key="i">{{ item[context.prop] }}</td> -->
                            <!-- {{ item.prop }} -->
                        </tr>
                    </table>
            </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex"



export default {
    data() {
        return {
            form:{
                username:'',
                password:'',
                usertype:'',
                score:'',
                email:'',
                countdown:'',
                userip:''

            },
            searched:false,
            username:'',
            phone:"",
            score:'',
            FileName: "JsToJsonFile",
            ShowListData: [],
            infoEnd:[],
            option: {
             
            column: [
            {
                label: '操作类型',
                prop: 'operType'
            },
            {
                label: '用户名',
                prop: 'userName'
            },
            {
                label: '用户类型',
                prop: 'userType'
            },
            {
                label: '时间',
                prop: 'time'
            },
            {
                label: '信息编号',
                prop: 'infoNum'
            },
            {
                label: '会员类型',
                prop: 'vipType'
            },
            {
                label: '积分类型',
                prop: 'jfType'
            }
            ]},
            tableData:[
                {
                    'operType':'1',
                    'userName':'2',
                    'userType':'1',
                    'time':'1',
                    'infoNum':'1',
                    'vipType':'1',
                    'jfType':'1'
                    
                }
            ]
     
        }
    },
    computed:{
         ...mapMutations(["changeFlag","changeFlag1"]),
    },
    created() {

    },
    methods: {
     
     
        onClickLeft(){
            window.history.back(-1)
        },
        onClickRight(){

        }
    },
   async mounted() {
    // this.ces = true
      // this.ces = true
    this.changeFlag();
  },
  destroyed() {
    this.changeFlag1();
  },

}
</script>

<style scoped>
/* .vant-table .table{
     border-radius: .185185rem;
}
.vant-table .table .th{
    height: 1.074074rem;
      line-height: 1.074074rem;
      background-color: #393943;
      text-align: center;
      border-top-left-radius: .185185rem;
      border-top-right-radius: .185185rem;
}
.vant-table .table .list-tr{
    height: 1.074074rem;
    line-height: 1.074074rem;
}
.vant-table .table .list-tr:nth-child(2n){
    background-color: #33333b;
} */
/*  */

* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
#container .content .info{
    width: 100%;
    height: 85px;
    padding: 5px;
}
#container .content .info_info{
    width: 100%;
    height: 150px !important;
    padding: 5px;
}
#container .content .info_info .shengji_shengji{
    width: 100%;
    height: 120px !important;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
#container .content .info_info .shengji_shengji .row{
    height: 33.33%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#container .content .info .title{
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-align: left;
}
#container .content .info .shengji{
  width: 100%;
  height: 50px;
  font-size: 14px;
}
#container .content .info .shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
#container .content .info .shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
#container .content .info li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
</style>